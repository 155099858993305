html{
  background-color: #fafafa;
}

.App{
  margin: 0;
  padding: 0;
  min-height: 100vh;
}

.Headerr{
  width: 99%;
  height: 11vh;
  display: grid;
  place-items: center;
}

.Headerr div{
  display: grid;
  grid-template-columns: repeat(5, 5fr);
  gap: 7%;
  place-content: center;
  max-width: 600px;
  width: 25vw;
  height: 33.3%;
}

.Headerr p, p:active, p:hover{
  text-decoration: none;
  font-family: 'Manrope', sans-serif;
  font-size: calc(14px + 1vw);
  margin-top: auto;
  width: auto;
  color: black;
  height: 100%;
  place-content: center;
  text-align: justify;
}

.Headerr img{
  width: calc(9px + 2vw);
  height: 100%;
}

.SnailLabs{
  position: relative;
  width: 50%;
  height: 0,26vh;
  left: 25%;
  margin-top: 10vw;
  max-height: 511px;
}

.SnailLabs img{
  width: 100%;
  height: 100%;
}

.Join{
  position: relative;
  margin-top: 3vh;
  border-radius: 5vw;
  width: 22vw;
  height: 5vw;
  background-color: #F65009;
  left: 38%;
  display: grid;
  place-content: center;
}

.Join p{
  position: relative;
  font-family: 'Manrope', sans-serif;
  font-size: calc(14px + 1vw);
  color: #fafafa;
}

.Banner1{
  margin-top: 1.3vw;
  width: 99vw;
  height: 100vh;
  left: 10%;
  background-image: url("./images/1.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 5vw;
}

.B_C{
  margin-top: 1.3vw;
  width: 99vw;
  height: 100vh;
  left: 10%;
  background-image: url("./images/1.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.Banner1 div{
  text-decoration: none;
  position: relative;
  border: 1px solid #F65009;
  border-radius: 5vw;
  width: 29vw;
  height: 5vw;
  display: grid;
  place-content: center;
  top: 87%;
  left: 35%;
}

.Banner1 p{
  font-family: 'Manrope', sans-serif;
  position: relative;
  font-size: calc(14px + 1vw);
  color: #282828;
}

.Banner2{
  width: 99vw;
  height: 100vh;
  left: 10%;
  background-image: url("./images/2.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 5vw;
}

.B_S{
  width: 99vw;
  height: 100vh;
  left: 10%;
  background-image: url("./images/2.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.Banner2 div{
  position: relative;
  border: 1px solid #F65009;
  border-radius: 5vw;
  width: 24vw;
  height: 5vw;
  display: grid;
  place-content: center;
  top: 87%;
  left: 38%;
}

.Banner2 p{
  font-family: 'Manrope', sans-serif;
  position: relative;
  font-size: calc(14px + 1vw);
  color: #282828;
}

.Banner3{
  width: 99vw;
  height: 100vh;
  left: 10%;
  background-image: url("./images/3.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 5vw;
}

.B_M{
  width: 99vw;
  height: 100vh;
  left: 10%;
  background-image: url("./images/3.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.Banner3 div{
  position: relative;
  border: 1px solid #F65009;
  border-radius: 5vw;
  width: 27vw;
  height: 5vw;
  display: grid;
  place-content: center;
  top: 87%;
  left: 37%;
}

.Banner3 p{
  font-family: 'Manrope', sans-serif;
  position: relative;
  font-size: calc(14px + 1vw);
  color: #282828;
}

.News{
  background-color: #282828;
}

.cont{
  width: 98vw;
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  gap: 4%;
}

.cont img{
  height: 45vw;
  width: 60vw;
}

.t1{
  margin-top: 3.4vw;
  width: 34vw !important;
  height: 38vw !important;
}

.t2{
  margin-top: 3.4vw;
  width: 34vw !important;
  height: 38vw !important;
  margin-left: 1vw;
}

.cont2{
  margin-top: 1vw;
  width: 98vw;
  display: grid;  
  grid-template-columns: repeat(2, 2fr);
  gap: 4%;
}

.cont2 img{
  height: 45vw;
  width: 60vw;
}

.cont3{
  margin-top: 1vw;
  width: 98vw;
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  gap: 4%;
}

.cont3 img{
  height: 45vw;
  width: 60vw;
}

.Contact p{
  font-family: 'Manrope', sans-serif;
  font-size: 3vw;
  font-weight: bold;
  color: #282828;
  margin-left: 1vw;
  margin-top: 3vw;
  
}

.Contact div{
  display: grid;
  grid-template-columns: repeat(2, 2fr);
}

.icont{
  width: 44vw !important;
  height: 12vw !important;
  margin-top: 8vw !important;
  margin-left: 0vw !important;
}

.Contact img{
  width: 98vw;
  height: 13vw;
  margin-left: 1vw;
}

.soc{
  width: 32vw;
  height: 9vw;
  display: grid !important; 
  grid-template-columns: repeat(3, 3fr) !important;
  gap: 3% !important;
  margin-top: 10vw;
  margin-bottom: 10vw;
}

.soc img{
  width: 9vw !important;
  height: 9vw !important;
}

.i1{
  margin-top: 7vw;
}

.i2{
  margin-top: 2vw;
}
.i_h{
  height: 100% !important;
}

.C1{
  width: 100%;
  height: 36vw;
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  gap: 14vw;
}

.c1p{
  width: 39vw;
  height: 30vw;
  margin-top: 3vw;
  margin-left: 6vw;
}

.c11p{
  width: 39vw;
  height: 30vw;
  margin-top: 1vw;
  margin-left: 6vw;
}

.c11t{
  width: 33vw;
  height: 19vw;
  margin-top: 10vw;
}

.c1t{
  width: 33vw;
  height: 19vw;
  margin-top: 12vw;
}

.C2{
  width: 100%;
  height: 39vw;
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  gap: 14vw;
  margin-bottom: 6vw;
}

.c2t{
  width: 33vw;
  height: 19vw;
  margin-top: 11vw;
  margin-left: 6vw;
}

.c2p{
  width: 39vw;
  height: 30vw;
}

.Mod{
  width: 100%;
  height: 52vw;
  background-image: url("./images/mod.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.L_C{
  width: 87vw;
  height: 13vw;
  margin-left: 7vw;
  margin-top: 6vw;
  margin-bottom: 11vw;
}

.S2{
  width: 100%;
  height: 39vw;
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  gap: 14vw;
}


.nav{
  text-decoration: none;
}



@media only screen and (max-width: 767px) {
  .Header div{
    max-width: 600px;
  }

  .Header img{
    width: calc(14px + 2vw);
  }

  .SnailLabs{
    width: 70%;
    height: 40%;
    left: 15%;
  }

  .Join {
    width: 68vw;
    height: 10vw;
    left: 16vw;
  }
  .Banner1{
    height: 33vh;
    width: 100vw;
    background-size: contain;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .B_C{
    height: 33vh;
    width: 100vw;
    background-size: contain;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .Banner1 p{
    font-size: 9px;
  }

  .Banner2{
    height: 33vh;
    width: 100vw;
    background-size: contain;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .B_S{
    height: 33vh;
    width: 100vw;
    background-size: contain;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .Banner2 p{
    font-size: 9px;
  }

  .Banner3{
    height: 33vh;
    width: 100vw;
    background-size: contain;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .B_M{
    height: 33vh;
    width: 100vw;
    background-size: contain;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }


  .Banner3 p{
    font-size: 9px;
  }
}
